import React from 'react';
import PocketBase from 'pocketbase';

const day = new Date().getDate();
var monthBad = new Date().getMonth()+1;
const month = monthBad < 10 ? "0"+monthBad : monthBad;
const year = new Date().getFullYear();

const dateTimestamp = `${year}-${month}-${day} 00:00:00.000Z`;

export const pb = new PocketBase('https://yournal.francperez.com');

export const LogOut = () => {
    pb.authStore.clear();
    window.location.reload(false);
  }

export const getJournalDB = async () => {
    var record = await pb.collection('journals').getList(1, 1, {
        filter: `date="${dateTimestamp}"`,
    });

    if (record.items.length == 0) {
        const data = {
            "plainText": "",
            "user": pb.authStore.model.id,
            "date": dateTimestamp,
        }

        record = await pb.collection('journals').create(data);
        console.log('Create new record');
        return String(data.plainText);
    }
    console.log("Data:",record.items[0].plainText);
    return String(record.items[0].plainText);
}

export const updateJournalDB = async (id, val) => {
    await pb.collection('journals').update(id, { plainText: val});
}