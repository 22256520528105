import React, { useState, useEffect, useContext, useRef } from "react";
import * as MUI from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';

import Auth from './Auth';
import { useEditor, EditorContent, BubbleMenu, FloatingMenu } from '@tiptap/react';
import Placeholder from '@tiptap/extension-placeholder'
import StarterKit from '@tiptap/starter-kit';
import Highlight from '@tiptap/extension-highlight'
import Underline from '@tiptap/extension-underline'

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import { FormatStrikethrough } from "@mui/icons-material";
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// PocketBase
import PocketBase from 'pocketbase';

const dayBad = new Date().getDate();
const day = dayBad < 10 ? "0"+dayBad : dayBad;
var monthBad = new Date().getMonth()+1;
const month = monthBad < 10 ? "0"+monthBad : monthBad;
const year = new Date().getFullYear();
const dateTimestamp = `${year}-${month}-${day} 00:00:00.000Z`;
const today = `${day}/${month}/${year}`;

const pb = new PocketBase('https://yournal.francperez.com');
// PocketBase

const theme = createTheme({
  typography: {
    fontFamily: [
      'Martian Mono', 
      'monospace',
    ].join(','),
  },
});

/**
 * <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;800&display=swap" rel="stylesheet">
 * font-family: 'Inter', sans-serif;
 */

const App = () => {

  const [auth, setAuth] = useState(pb.authStore.model);
  const [currentState, setCurrentState] = useState({});
  const [userJours, setUserJours] = useState([]);
  const [dbLoading, setDBLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [open, setOpen] = useState(false);

  // HANDLERS
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleCurrentState = ( item ) => {
    setCurrentState(item);
    editor.commands.setContent(currentState.contentHTML);
  };

  // END HANDLERS
  const editor = useEditor({
    extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder: 'New day, new jour!',
        }),
        Highlight.configure({ multicolor: true }),
        Underline,
      ]
    });

  // Backend Methods
  const LogOut = () => {
    pb.authStore.clear();
    window.location.reload(false);
  }

  const createTodayJournal = async () => {
    const data = {
        "contentPlain": "",
        "contentHTML": "",
        "user": pb.authStore.model.id,
        "date": dateTimestamp,
    }

    await pb.collection('journals').create(data);
    return;
  }

  const getUserJournalsDB = async (p) => {
    setDBLoading(true);
    await pb.collection('journals').getList(1, 50, {
      sort: '-date',
    }).then(res => {
      if(res.items[0].date === dateTimestamp) {
        setUserJours(res.items);
        setSearchResult(res.items);
        if ( p ) {
          setCurrentState(res.items[0]);
        }
        setDBLoading(false);
      } else {
        createTodayJournal().then(getUserJournalsDB(true));
      }
    });
  }

  const updateJournalDB = async ( editorObj ) => {
    const data = { 
              "contentHTML": editorObj.getHTML(),
              "contentPlain": editorObj.getText(),
              "date": currentState.date,
              "user": currentState.user,
            }
    await pb.collection('journals').update(currentState.id, data).then( res => setCurrentState(res));
    getUserJournalsDB(false);
  }

  const search = async (query) => {
    if (query === "") {
      setSearchResult(userJours);
      return;
    }
    await pb.collection('journals').getList(1, 50, {
      filter: `contentPlain~"%${query}%" || date~"${query}"`,
    }).then( (res) => {
      setSearchResult(res.items);
    });
    
    return;
  }
  // End Backend Methods

  // Get data from DB
  useEffect(()=>{
    getUserJournalsDB(true);
  }, []);

  // Handle updates
  useEffect(()=>{
    if (!dbLoading) {
      editor.on('blur', ({ editor, event }) => {
        updateJournalDB( editor );
      });
    }
  });

  // Update editor content at the beginning
  useEffect(() => {
    if (!dbLoading) {
      editor.commands.setContent(currentState.contentHTML);
    }
  }, [userJours]);

  if (auth === null){
    return (
      <ThemeProvider theme={theme}>
        <MUI.Container sx={{ 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
        }}>
        <Auth />
        </MUI.Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
    <MUI.Container maxWidth="lg" >
      <MUI.CssBaseline />
      <MUI.AppBar
        position="fixed" 
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'black'}} 
      >
        <MUI.Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              { open ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          <MUI.Typography variant="h5" noWrap component="div">
            jour.app
          </MUI.Typography>
        </MUI.Toolbar>
      </MUI.AppBar>
      <MUI.Drawer
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { xs: '100%', lg: '60%'} },
          }}
        >
          <MUI.Toolbar />
          <MUI.Box sx={{ display: 'flex', flexDirection: 'column', p: 2}}>
            <MUI.TextField 
              value={searchQuery} 
              onChange={ (e) => {
                if ( e.target.value === "" ) {
                  search("");
                }
                setSearchQuery(e.target.value);
              }}
              onKeyDown={(e) => {
                if ( e.key === 'Enter') {
                  search(e.target.value);
                }
              }}
              id="outlined" 
              label="Search words and dates!" 
              variant="standard"
              color="secondary"
              sx={{ m:1, marginTop: 2 }}
            />
            { searchResult.length > 0 ?
              searchResult.map( (item, i) => (
                  <MUI.Paper key={i} elevation={2} sx={{ margin: 1, padding: 1, border: '3px solid black' }}>
                    <MUI.Typography variant="h5" component="div">
                      {item.date}
                    </MUI.Typography>
                    <MUI.Typography variant="body1" component="div">
                      {item.contentPlain}
                    </MUI.Typography>
                    <MUI.Divider sx={{ m: 1 }} />
                    <MUI.Button variant="outlined" color="secondary" sx={{ color: 'black' }}
                    onClick={() => {
                      handleCurrentState(item);
                      handleDrawerToggle();
                    }}>OPEN</MUI.Button>
                  </MUI.Paper>
                ))
            :
              <MUI.Paper elevation={2} sx={{ margin: 1, padding: 1 }}>
                <MUI.Typography variant="body1" noWrap component="div">
                  No results found...
                </MUI.Typography>
              </MUI.Paper>
            }
          </MUI.Box>
        </MUI.Drawer>

        {editor && <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
            <MUI.ButtonGroup variant="contained" size="small">
              <MUI.Button 
                onClick={() => editor.chain().focus().toggleBold().run()} 
                color={editor.isActive('bold') ? 'secondary' : 'primary'}>
                <FormatBoldIcon />
              </MUI.Button>
              <MUI.Button 
              onClick={() => editor.chain().focus().toggleItalic().run()}
              color={editor.isActive('italic') ? 'secondary' : 'primary'}>
                <FormatItalicIcon />
              </MUI.Button>
              <MUI.Button 
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              color={editor.isActive('underline') ? 'secondary' : 'primary'}>
                <FormatUnderlinedIcon />
              </MUI.Button>
              <MUI.Button 
              onClick={() => editor.chain().focus().toggleStrike().run()}
              color={editor.isActive('strike') ? 'secondary' : 'primary'}>
                <FormatStrikethrough />
              </MUI.Button>
              <MUI.Button 
              onClick={() => editor.chain().focus().toggleHighlight({ color: '#ea86ff'}).run()}
              color={editor.isActive('highlight') ? 'secondary' : 'primary'}>
                <FormatColorFillIcon />
              </MUI.Button>
            </MUI.ButtonGroup>
          </BubbleMenu>}
        {editor && <FloatingMenu editor={editor} tippyOptions={{ duration: 100 }}>
          <MUI.ToggleButtonGroup
            aria-label="text formatting"
            size="small"
          >
          <MUI.ToggleButton 
            value="title" 
            aria-label="title toggle" 
            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
            className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}>
              TITLE
            </MUI.ToggleButton>
          <MUI.ToggleButton 
            value="subtitle" 
            aria-label="subtitle toggle"
            onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
            className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}>
              SUBTITLE
          </MUI.ToggleButton>
          </MUI.ToggleButtonGroup>
        </FloatingMenu>}

      <MUI.Box>
        <MUI.Toolbar />
        <MUI.Typography variant="h4" paddingTop={2}>{currentState.date}</MUI.Typography>
        { currentState.date === dateTimestamp ? <></> : 
        <MUI.Button variant="outlined" color="secondary" sx={{ color: 'black', marginTop: 1 }}
        onClick={() => handleCurrentState(userJours[0])}>BACK TO TODAY</MUI.Button>
        }
        <MUI.Box sx={{ maxHeight: '75vh', marginTop: 2, border: '3px solid black', borderRadius: '0.2rem', overflow: 'auto' }}>
              <EditorContent editor={editor} />
        </MUI.Box>
      </MUI.Box>
    </MUI.Container>
    </ThemeProvider>
  );
}

export default App;