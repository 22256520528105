import React, {useState} from "react";
import * as MUI from '@mui/material';

import {pb} from './pocketbase';

const Auth = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleUsername = (event) => {
        setUsername(event.target.value);
    }

    const handlePassword = (event) => {
        setPassword(event.target.value);
    }

    const LogIn = async () => {
        const authData = await pb.collection('users').authWithPassword(
            username,
            password,
        );
        window.location.reload(false);
    }

    return (
        <MUI.Paper elevation={4} sx={{
            height: '50vh',
            width: '40vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        }}>
            <MUI.Typography variant="h3" gutterBottom>Yournal.</MUI.Typography>
            <MUI.Typography variant="body1" gutterBottom>Minimal bullet journal</MUI.Typography>
            <MUI.TextField
                color="secondary" 
                id="outlined-multiline-flexible"
                label="Username / Email"
                maxRows={1}
                sx={{ width: '80%', margin: 1}}
                onChange={handleUsername}
            />
            <MUI.TextField
                color="secondary"
                id="outlined-multiline-flexible"
                label="Password"
                type="password"
                maxRows={1}
                sx={{ width: '80%', margin: 1}}
                onChange={handlePassword}
            />
            <MUI.Button variant="contained" color="secondary" onClick={LogIn}>Log In</MUI.Button>
        </MUI.Paper>
    );
}

export default Auth;